var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Edit Extra Item", "after-close": _vm.afterModalClose, "width": 550 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { key: "CountryId", attrs: { "label": "Country", "reference": "ppv.common.ppv-countries", "placeholder": "Select Country", "source": "countryId", "source-label": "countryCode", "rules": "required", "span": 4, "form-item": "", "disabled": true, "value": _vm.entity.countryId, "on-select-change": _vm.onCountryChange } })], 1), _c("a-col", { attrs: { "span": 10 } }, [_c("text-input", { key: "Item", attrs: { "label": "Item Code", "placeholder": "Type Item Code", "rules": "required", "disabled": true, "form-item": "", "span": 12, "value": _vm.entity.item }, on: { "change": _vm.onItemChange } })], 1), _c("a-col", { attrs: { "span": 10 } }, [_c("text-input", { key: "ItemDesc", attrs: { "label": "Item Desc", "placeholder": "Type Item Desc", "rules": "required", "form-item": "", "span": 12, "value": _vm.entity.itemDesc }, on: { "change": _vm.onItemDescChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "ItemClass", attrs: { "label": "Item Class", "data-source": _vm.itemClassList, "placeholder": "Select Item Class", "source": "itemClass", "source-label": "itemClassDesc", "rules": "required", "span": 10, "form-item": "", "value": _vm.entity.itemClass }, on: { "change": _vm.onItemClassChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "SubClassKey", attrs: { "label": "Sub Class", "data-source": _vm.subClassList, "placeholder": "Select Country", "source": "subClass", "source-label": "subClassDesc", "rules": "required", "span": 10, "form-item": "", "value": _vm.entity.subClassKey }, on: { "change": _vm.onSubClassChange } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditExtraItems",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      subClassList: [],
      itemClassList: [],
      CountryId: "",
      item: "",
      itemDesc: "",
      itemClass: "",
      subClassKey: "",
      country: this.crud.getQueryString("CountryID").value
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  created() {
    this.getSubClassList();
    this.getItemClassList();
    this.storeValue("CountryId", this.country);
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/ppv/extra-items`, {
        CountryId: this.country,
        Item: this.item ? this.item : this.entity.item,
        ItemDesc: this.itemDesc ? this.itemDesc : this.entity.itemDesc,
        ItemClass: this.itemClass ? this.itemClass : this.entity.itemClass,
        SubClassKey: this.subClassKey ? this.subClassKey : this.entity.subClassKey
      }).then(() => {
        this.isLoading = true;
        this.visible = false;
        this.crud.fetchList();
        this.$notification["success"]({
          message: "Item Updated!"
        });
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Something went wrong, please try again!"
        });
      });
    },
    getSubClassList() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-sub-class?CountryId=${this.country}`).then((res) => {
        let { data: subClassList } = res;
        subClassList = [...subClassList];
        this.subClassList = subClassList;
      });
    },
    getItemClassList() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-item-class?CountryId=${this.country}`).then((res) => {
        let { data: itemClassList } = res;
        itemClassList = [...itemClassList];
        this.itemClassList = itemClassList;
      });
    },
    onCountryChange(value) {
      this.country = value;
      this.getSubClassList();
      this.getItemClassList();
    },
    storeValue(resourceKey, value) {
      const createForm = this.createForm;
      this.createForm = __spreadProps(__spreadValues({}, createForm), {
        [resourceKey]: value
      });
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    onSubClassChange(value) {
      this.subClassKey = value;
    },
    onItemChange(value) {
      this.item = value;
    },
    onItemDescChange(value) {
      this.itemDesc = value;
    },
    onItemClassChange(value) {
      this.itemClass = value;
    },
    afterModalClose() {
      this.$router.push("/ppv-actuals/extra-items");
    },
    onCancel() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditExtraItems = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "ppv.common.ppv-countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.extra-items", "api-url": _vm.apiUrl, "redirect-route": "/ppv-actuals/extra-items" } }, [_c("edit-extra-items")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditExtraItems
  },
  data() {
    return {
      EditExtraItems,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
